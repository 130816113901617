import { Injectable } from "@angular/core";
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
} from "@angular/common/http";
import { catchError, Observable, switchMap, throwError } from "rxjs";
import { TokenService } from "../shared/services/auth/token.service";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private authService: TokenService) {} // Injeksi service AuthService
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    const token = this.authService.getAccessToken(); // Atau diambil dari service lain

    if (!token) {
      // Jika token kosong, maka request akan dihentikan
      return next.handle(req.clone({ withCredentials: true })).pipe();
    }

    if (token) {
      const clonedReq = req.clone({
        headers: req.headers.set("Authorization", `Bearer ${token}`),
      });

      // return next.handle(clonedReq);
      return next.handle(clonedReq).pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            // Token might be expired, try to refresh it
            return this.authService.refreshToken().pipe(
              switchMap((newToken: any) => {
                // Save new token and retry the original request
                this.authService.setAccessToken(newToken.data.token);
                this.authService.setRefreshToken(newToken.data.refresh_token);
                const clonedReq = req.clone({
                  setHeaders: {
                    Authorization: `Bearer ${newToken.data.token}`,
                  },
                });
                return next.handle(clonedReq);
              }),
              catchError(err => {
                // If refresh fails, logout the user or handle accordingly
                this.authService.logout();
                return throwError(err);
              }),
            );
          } else {
            return throwError(error);
          }
        }),
      );
    } else {
      return next.handle(req);
    }
  }
}
