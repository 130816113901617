import { Routes } from "@angular/router";

export const content: Routes = [
  {
    path: "dashboard",
    loadChildren: () =>
      import("../../components/dashboard/dashboard.module").then(
        m => m.DashboardModule,
      ),
  },
  {
    path: "widgets",
    loadChildren: () =>
      import("../../components/widgets/widgets.module").then(
        m => m.WidgetsModule,
      ),
  },
  {
    path: "simple-page",
    loadChildren: () =>
      import("../../components/simple-page/simple-page.module").then(
        m => m.SimplePageModule,
      ),
  },
  {
    path: "single-page",
    loadChildren: () =>
      import("../../components/single-page/single-page.module").then(
        m => m.SinglePageModule,
      ),
  },
  {
    path: "master",
    loadChildren: () =>
      import("../../components/master/master.module").then(m => m.MasterModule),
  },
  {
    path: "operasional",
    loadChildren: () =>
      import("../../components/operasional/operasional.module").then(
        m => m.OperasionalModule,
      ),
  },
  {
    path: "shipment",
    loadChildren: () =>
      import("../../components/shipment/shipment.module").then(
        m => m.ShipmentModule,
      ),
  },
  {
    path: "delivery-request",
    loadChildren: () =>
      import("../../components/delivery-request/delivery-request.module").then(
        m => m.DeliveryRequestModule,
      ),
  },
  {
    path: "non-delivery-request",
    loadChildren: () =>
      import(
        "../../components/non-delivery-request/non-delivery-request.module"
      ).then(m => m.NonDeliveryRequestModule),
  },
  {
    path: "manifest",
    loadChildren: () =>
      import("../../components/manifest/manifest.module").then(
        m => m.ManifestModule,
      ),
  },
  {
    path: "assignment-manifest",
    loadChildren: () =>
      import(
        "../../components/assignment-manifest/assignment-manifest.module"
      ).then(m => m.AssignmentManifestModule),
  },
  {
    path: "assignment-delivery",
    loadChildren: () =>
      import(
        "../../components/assignment-delivery/assignment-delivery.module"
      ).then(m => m.AssignmentDeliveryModule),
  },
  {
    path: "kedatangan",
    loadChildren: () =>
      import("../../components/kedatangan/kedatangan.module").then(
        m => m.KedatanganModule,
      ),
  },
  {
    path: "customer-service",
    loadChildren: () =>
      import("../../components/customer-service/customer-service.module").then(
        m => m.CustomerServiceModule,
      ),
  },
  {
    path: "account",
    loadChildren: () =>
      import("../../components/account/account.module").then(
        m => m.AccountModule,
      ),
  },
  {
    path: "setting",
    loadChildren: () =>
      import("../../components/setting/setting.module").then(
        m => m.SettingModule,
      ),
  },
];
