import { Injectable, OnDestroy } from "@angular/core";
import { Subject, BehaviorSubject, fromEvent } from "rxjs";
import { takeUntil, debounceTime } from "rxjs/operators";
import { Router } from "@angular/router";

// Menu
export interface Menu {
  headTitle1?: string;
  headTitle2?: string;
  path?: string;
  title?: string;
  icon?: string;
  type?: string;
  badgeType?: string;
  badgeValue?: string;
  active?: boolean;
  bookmark?: boolean;
  children?: Menu[];
}

@Injectable({
  providedIn: "root",
})
export class NavService implements OnDestroy {
  private unsubscriber: Subject<any> = new Subject();
  public screenWidth: BehaviorSubject<number> = new BehaviorSubject(
    window.innerWidth,
  );

  // Search Box
  public search: boolean = false;

  // Language
  public language: boolean = false;

  // Mega Menu
  public megaMenu: boolean = false;
  public levelMenu: boolean = false;
  public megaMenuColapse: boolean = window.innerWidth < 1199 ? true : false;

  // Collapse Sidebar
  public collapseSidebar: boolean = window.innerWidth < 991 ? true : false;

  // For Horizontal Layout Mobile
  public horizontal: boolean = window.innerWidth < 991 ? false : true;

  // Full screen
  public fullScreen: boolean = false;

  constructor(private router: Router) {
    this.setScreenWidth(window.innerWidth);
    fromEvent(window, "resize")
      .pipe(debounceTime(1000), takeUntil(this.unsubscriber))
      .subscribe((evt: any) => {
        this.setScreenWidth(evt.target.innerWidth);
        if (evt.target.innerWidth < 991) {
          this.collapseSidebar = true;
          this.megaMenu = false;
          this.levelMenu = false;
        }
        if (evt.target.innerWidth < 1199) {
          this.megaMenuColapse = true;
        }
      });
    if (window.innerWidth < 991) {
      // Detect Route change sidebar close
      this.router.events.subscribe(event => {
        this.collapseSidebar = true;
        this.megaMenu = false;
        this.levelMenu = false;
      });
    }
  }

  ngOnDestroy() {
    // this.unsubscriber.next();
    this.unsubscriber.complete();
  }

  private setScreenWidth(width: number): void {
    this.screenWidth.next(width);
  }

  MENUITEMS: Menu[] = [
    {
      title: "Dashboard",
      icon: "home",
      type: "sub",
      active: false,
      children: [
        { path: "/dashboard/default", title: "Dashboard Utama", type: "link" },
        // { path: "/widgets/chart", title: "Chart", type: "link" },
      ],
    },
    {
      title: "DR",
      icon: "button",
      type: "sub",
      badgeType: "light-primary",
      active: false,
      children: [
        // { path: "/delivery-request/upload", title: "Upload", type: "link" },
        { path: "/delivery-request/pisah", title: "Upload DR", type: "link" },
        { path: "/delivery-request/list", title: "Daftar DR", type: "link" },
        {
          path: "/delivery-request/monitor",
          title: "Monitoring DR",
          type: "link",
        },
        {
          path: "/delivery-request/pickup-list",
          title: "Plan Pickup",
          type: "link",
        },
        {
          path: "/delivery-request/inbound-validation",
          title: "Inbound Validation",
          type: "link",
        },
        {
          path: "/delivery-request/allocation",
          title: "Alokasi",
          type: "link",
        },
        {
          path: "/delivery-request/outbound-validation",
          title: "Outbound Validation",
          type: "link",
        },
      ],
    },
    {
      title: "Non DR",
      icon: "button",
      type: "sub",
      badgeType: "light-primary",
      active: false,
      children: [
        {
          path: "/non-delivery-request/pickup-list",
          title: "Pickup",
          type: "link",
        },
        {
          path: "/non-delivery-request/inbound-validation",
          title: "Inbound Validation",
          type: "link",
        },
        {
          path: "/non-delivery-request/outbound-validation",
          title: "Outbound Validation",
          type: "link",
        },
        {
          path: "/non-delivery-request/monitor",
          title: "Monitoring DR",
          type: "link",
        },
      ],
    },
    {
      title: "Shipment",
      icon: "learning",
      type: "sub",
      badgeType: "light-primary",
      active: false,
      children: [
        { path: "/shipment/pickup", title: "Daftar AWB", type: "link" },
        { path: "/shipment/entry", title: "Input AWB", type: "link" },
        {
          path: "/shipment/osincomingwh",
          title: "Konfirmasi Incoming WH",
          type: "link",
        },
        // {
        //   path: "/shipment/shipment-pickup",
        //   title: "Pickup",
        //   type: "link",
        // },
      ],
    },
    {
      title: "Manifest",
      icon: "icons",
      type: "sub",
      badgeType: "light-primary",
      active: false,
      children: [
        {
          path: "/manifest/daftar-unmanifest",
          title: "Daftar unManifest",
          type: "link",
        },
        {
          path: "/manifest/daftar-manifest",
          title: "Daftar Manifest",
          type: "link",
        },
        {
          path: "/manifest/input-manifest",
          title: "Input Manifest",
          type: "link",
        },
      ],
    },
    {
      title: "Assignment Manifest",
      icon: "maps",
      type: "sub",
      badgeType: "light-primary",
      active: false,
      children: [
        {
          path: "/assignment-manifest/os-keberangkatan",
          title: "OS Keberangkatan",
          type: "link",
        },
        {
          path: "/assignment-manifest/daftar-keberangkatan",
          title: "Daftar Keberangkatan",
          type: "link",
        },
        {
          path: "/assignment-manifest/input-keberangkatan",
          title: "Input Keberangkatan",
          type: "link",
        },
      ],
    },
    {
      path: "/assignment-delivery",
      title: "Assignment Delivery",
      icon: "sample-page",
      type: "link",
    },
    {
      title: "Kedatangan",
      icon: "job-search",
      type: "sub",
      badgeType: "light-primary",
      active: false,
      children: [
        {
          path: "/kedatangan/informasi-kedatangan",
          title: "Informasi Kedatangan",
          type: "link",
        },
      ],
    },
    {
      title: "Customer Service",
      icon: "contact",
      type: "sub",
      badgeType: "light-primary",
      active: false,
      children: [
        {
          path: "/customer-service/status-resi",
          title: "Status Resi",
          type: "link",
        },
        {
          path: "/customer-service/revisi-resi",
          title: "Revisi Status",
          type: "link",
        },
      ],
    },
    {
      title: "Data Master",
      icon: "to-do",
      type: "sub",
      badgeType: "light-primary",
      active: false,
      children: [
        {
          title: "Perusahaan",
          icon: "file-text",
          type: "sub",
          active: false,
          children: [
            {
              path: "/operasional/group-company",
              title: "Pusat",
              type: "link",
            },
            { path: "/operasional/company", title: "Cabang", type: "link" },
          ],
        },

        {
          title: "Area",
          icon: "file-text",
          type: "sub",
          active: false,
          children: [
            { path: "/master/country", title: "Negara", type: "link" },
            { path: "/master/province", title: "Provinsi", type: "link" },
            { path: "/operasional/hub", title: "Hub", type: "link" },
            {
              path: "/operasional/destination",
              title: "Kota",
              type: "link",
            },
          ],
        },
        {
          title: "Kontak",
          icon: "file-text",
          type: "sub",
          active: false,
          children: [
            {
              path: "/operasional/customer",
              title: "Customer",
              type: "link",
            },
            {
              path: "/operasional/vendor",
              title: "Vendor",
              type: "link",
            },
            {
              path: "/operasional/internal",
              title: "Internal",
              type: "link",
            },
            {
              path: "/operasional/driver",
              title: "Driver",
              type: "link",
            },
          ],
        },
        {
          title: "Operasional",
          icon: "file-text",
          type: "sub",
          active: false,
          children: [
            { path: "/master/layanan", title: "Layanan", type: "link" },
            { path: "/master/uom", title: "Unit", type: "link" },
          ],
        },
        {
          title: "Transport",
          icon: "file-text",
          type: "sub",
          active: false,
          children: [
            {
              path: "/master/kategori-kendaraan",
              title: "Kategori Kendaraan",
              type: "link",
            },
            { path: "/master/kendaraan", title: "Kendaraan", type: "link" },
          ],
        },

        // {
        //   path: "/master/payment-method",
        //   title: "Payment Method",
        //   type: "link",
        // },
        // { path: "/master/moda", title: "Moda", type: "link" },

        {
          title: "Lain-lain",
          icon: "file-text",
          type: "sub",
          active: false,
          children: [
            { path: "/master/status", title: "Status", type: "link" },
            {
              path: "/master/status-delivery",
              title: "Status Pengiriman",
              type: "link",
            },
            {
              path: "/master/status-pickup",
              title: "Skenario DR",
              type: "link",
            },
          ],
        },
      ],
    },
  ];

  // Array
  items = new BehaviorSubject<Menu[]>(this.MENUITEMS);
}
