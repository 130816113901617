import { Component, OnInit } from "@angular/core";
import { FormBuilder, Validators, FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { LoginService } from "../../shared/services/auth/login.service";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  public newUser = false;
  // public user: firebase.User;
  public loginForm: FormGroup;
  public show: boolean = false;
  public errorMessage: any;

  constructor(
    private fb: FormBuilder,
    public router: Router,
    private loginService: LoginService,
  ) {
    this.loginForm = this.fb.group({
      email: ["", [Validators.required, Validators.email]],
      password: ["", Validators.required],
    });
  }

  ngOnInit() {}

  async login() {
    try {
      const data = await this.loginService.Login(
        this.loginForm.value["email"],
        this.loginForm.value["password"]
      );
      const me = await this.loginService.CurrentUser(
        data.data.token
      );
      localStorage.setItem("user", JSON.stringify(me.data));
      localStorage.setItem("token", data.data.token);
      localStorage.setItem("refresh_token", data.data.refresh_token);
      this.router.navigate(["/dashboard/default"]);
    } catch (error) {
      console.error("There was an error!", error);
    }
  }

  showPassword() {
    this.show = !this.show;
  }
}
