<div class="media profile-media">
  <img
    class="b-r-10"
    style="height: 35px"
    src="assets/images/dashboard/courier-delivery.png"
    alt=""
  />
  <div class="media-body">
    <span>KTI admin</span>
    <p class="mb-0 font-roboto">
      Admin <i class="middle fa fa-angle-down"></i>
    </p>
  </div>
</div>
<ul class="profile-dropdown onhover-show-div">
  <li>
    <a href="/account"
      ><app-feather-icons [icon]="'user'"></app-feather-icons
      ><span>Account </span></a
    >
  </li>
  <!-- <li><a href="javascript:void(0)"><app-feather-icons [icon]="'mail'"></app-feather-icons><span>Inbox</span></a></li> -->
  <!-- <li><a href="javascript:void(0)"><app-feather-icons [icon]="'file-text'"></app-feather-icons><span>Taskboard</span></a></li> -->
  <li>
    <a href="/setting"
      ><app-feather-icons [icon]="'settings'"></app-feather-icons
      ><span>Settings</span></a
    >
  </li>
  <li (click)="logoutFunc()">
    <app-feather-icons [icon]="'log-in'"></app-feather-icons
    ><span>Log out</span>
  </li>
</ul>
