import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class TokenService {
  private accessToken: string | null = localStorage.getItem("token");
  private refToken: string | null = localStorage.getItem("refresh_token");

  private apiUrl = `${environment.apiUrl}`;

  constructor(private http: HttpClient) {}

  getAccessToken(): string | null {
    return this.accessToken;
  }

  setAccessToken(token: string): void {
    localStorage.setItem("token", token);
  }

  setRefreshToken(token: string): void {
    localStorage.setItem("refresh_token", token);
  }

  refreshToken(): Observable<any> {
    // Make an HTTP request to refresh the token
    return this.http.post<any>(this.apiUrl + "/auth/refresh_token", {
      refresh_token: this.refToken,
    });
  }

  logout(): void {
    // Handle logout, maybe remove tokens and redirect to login
    localStorage.setItem("token", null);
    localStorage.setItem("refresh_token", null);
  }
}
