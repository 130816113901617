<!-- footer start-->
<div class="container-fluid">
  <div class="row">
    <div class="col-md-12 footer-copyright">
      <p class="mb-0 text-sm">
        Copyright {{ today | date: "y" }} © Koding Integrasi Solusi by
        BIKINKODING | Versi 0.0.1
      </p>
    </div>
  </div>
</div>
